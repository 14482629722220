import { isNil } from 'lodash-es'

export const useFormArray = ({ fields = [], min = 0, max = null }) => {
    const isReachMin = computed(() => unref(fields).length <= unref(min))
    const isReachMax = computed(() => {
        const unrefMax = unref(max)

        return unref(fields).length >= unrefMax && !isNil(unrefMax)
    })

    return {
        isReachMax,
        isReachMin,
    }
}
